<template>
    <div>
         <div class="isheader">
             <span class="is-span-header go" @click="$router.push('/MyShopping')"></span>
             <span class="is-span-header">确认采购价格</span>
             <span class="is-span-header" @click="islogout">退出</span>
        </div>
        <div class="content">
            <p>选择运输批次: <span>{{dataLsta.batch_number}}--{{dataLsta.carNumberPlate}}</span> </p>
            <p>过磅重量: <span>{{dataLsta.batch_average_weight?dataLsta.batch_average_weight:0}}Kg</span> </p>
            <p>扣杂重量: <span>{{dataLsta.suttle_batch_average_weight?dataLsta.suttle_batch_average_weight:0}}Kg</span> </p>
            <p v-if="purchaseType==3">罚款金额：<span>{{dataLsta.fine_price}}</span> </p>
            <p class="sun">
                净重：<span style="color:red">{{Sun}}KG</span>
            </p>
            <p>质检增费项目:</p>
            <table >
                <tr>
                    <td>项目</td>
                    <td>单价</td>
                    <td>数量</td>
                    <td>增费</td>
                </tr>
                <tr v-for="i in dataLsta.quality_price_data" :key="i.id">
                    <td>{{i.name}}</td>
                    <td>{{i.unit_price}}</td>
                    <td>{{i.num}}</td>
                    <td>{{i.price}}</td>
                </tr>
            </table>
            <p class="sun">
                质检增费合计：<span style="color:red">{{dataLsta.sum_quality_price}}元</span>
            </p>
            <p>质检扣费项目:</p>
            <table >
                <tr>
                    <td>项目</td>
                    <td>单价</td>
                    <td>数量</td>
                    <td>扣费</td>
                </tr>
                <tr v-for="i in dataLsta.deduction_price_data" :key="i.id">
                    <td>{{i.name}}</td>
                    <td>{{i.unit_price}}</td>
                    <td>{{i.num}}</td>
                    <td>{{i.price}}</td>
                </tr>
            </table>
            <p class="sun">
                质检扣费合计：<span style="color:red">{{dataLsta.sum_deduction_price}}元</span>
            </p>
            <div>
                <p>选择采购计费方式：</p>
                    <van-field readonly clickable  name="picker"  :value="graceless"  placeholder="请选择采购计费方式"  @click="openShow" />
                    <van-popup v-model="show" position="bottom" :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="columns"
                        value-key="name"
                        @confirm="onConfirm1"
                        @cancel="show = false"
                    />
                    </van-popup>
            </div>
            <div v-if="gracelessId == 1">
                <p>选择统货采购价格：</p>
                    <van-field readonly clickable  name="picker"  :value="priceNmae"  placeholder="请选择统货采购价格"  @click="openShowPicker" />
                    <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="purchasePrice"
                        value-key="test"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                    />
                </van-popup>
            </div>
           
            <div>
                <van-field v-if="gracelessId==2" v-model="value" label="输入特殊采购价格（元/吨）：" label-width="150" placeholder="请输入特殊采购价格" /> 
                <p>计费合计：<span style="color:red">{{result}}元</span></p> 
                <van-field @focus="colseling1(2)" v-model="other_cost_price" label="其他成本：" label-width="150" placeholder="请输入其他成本" />  


                <br>
                <div v-if="this.purchaseType != 3">

                    <van-radio-group v-model="shipping_cost_type" direction="horizontal">
                        <van-radio :name="2">支出运费</van-radio>
                        <van-radio :name="1">收入运费</van-radio>
                    </van-radio-group>
                    <br>
                    <van-field @focus="colseling1(1)" v-model="shipping_cost_price" label="费运：" label-width="150" />  
                </div>
                <van-field v-else @focus="colseling1(1)" v-model="shipping_cost_price" label="运输成本：" label-width="150" placeholder="请输入运输成本" />  
               
            </div>
        </div>
        <div class="btn-buttom" v-if="Complete != 1">
            <van-button type="info" size="large" @click="onSubmit">提交</van-button>
        </div>
    </div>
</template>

<script>
import { is_post } from "../../Http/api";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        data() {
            return {
                dataLsta:[],
                columns:[
                    {name:'统货采购',id:1},
                    {name:'特殊采购',id:2},
                ],
                purchasePrice:[],
                showPicker:false,
                show:false,
                gracelessId:'',
                graceless:'',
                priceNmae:"",
                priceId:"",
                other_cost_price:2,
                shipping_cost_price:"",
                priceArr:[],
                goods:{},
                value:null,
                shipping_cost_type:"",
                // result:'',
            }
        },
        mounted () {
            this.lodData();
            this.getType();
        },
        computed: {
            Sun() {
                let a= 0
                    a = this.dataLsta.batch_average_weight - this.dataLsta.suttle_batch_average_weight
                return a?a:0
            },
            Complete(){
                return this.$route.query.isComplete ||''
            },
            purchaseType(){
                return this.$route.query.purchaseType ||''
            },
            result(){
                let he,a = 0
                 if(this.goods){
                    a = !this.dataLsta.fine_price ?
                    ((Number(this.goods.price) / 1000) * (this.dataLsta.batch_average_weight - this.dataLsta.suttle_batch_average_weight)) + Number(this.dataLsta.sum_quality_price) - Number(this.dataLsta.sum_deduction_price) 
                    :
                    ((Number(this.goods.price) / 1000) * (this.dataLsta.batch_average_weight - this.dataLsta.suttle_batch_average_weight)) - Number(this.dataLsta.fine_price)
                    he = a?a.toFixed(2):0
                }
                if(this.value){
                     a = !this.dataLsta.fine_price ?
                    ((Number(this.value) / 1000) * (this.dataLsta.batch_average_weight - this.dataLsta.suttle_batch_average_weight)) + Number(this.dataLsta.sum_quality_price) - Number(this.dataLsta.sum_deduction_price) 
                    :
                    ((Number(this.value) / 1000) * (this.dataLsta.batch_average_weight - this.dataLsta.suttle_batch_average_weight)) - Number(this.dataLsta.fine_price)
                    he = a?a.toFixed(2):0
                  
                }
                console.log(this.goods,this.value,he)
                return he
            },
        },
        methods: {
            openShow(){
                if(this.Complete != 1){
                    this.show = true
                }
            },
            openShowPicker(){
                if(this.Complete != 1){
                    this.showPicker = true
                }
            },
            colseling1(num){
                if(num === 1) {
                    this.shipping_cost_price = this.shipping_cost_price==0 ? null : this.shipping_cost_price 
                }else if(num ===2){
                    this.other_cost_price = this.other_cost_price==0 ? null : this.other_cost_price 
                }
            },
            async lodData() {
                try{
                    let aa = encipherMent( JSON.stringify({batch_id:this.$route.query.id}))
                    let param = new URLSearchParams();
                    param.append("value",aa);
                    let url = this.purchaseType == 3 ?'index.php/scrap_iron/qualityFind':'index.php/batch/qualityFind'
                    const data = await is_post(url,param).then(res=>res)
                    this.dataLsta = data.data
                    console.log(data)
                    this.shipping_cost_price = data.data.shipping_cost_price
                    this.other_cost_price = data.data.other_cost_price
                    this.shipping_cost_type =  data.data.shipping_cost_type
                    if(this.Complete == 1) {
                        this.gracelessId = data.data.type
                        this.graceless = data.data.type == 1 ? '统货采购':'特殊采购'
                        this.priceId = data.data.statistics_price_id
                        this.goods.price = data.data.type == 1 ? data.data.price: 0
                        this.priceNmae = data.data.name +'(' + data.data.price+')'
                        this.value = data.data.type == 2? data.data.purchase_amount_price:null
                    }
                 }
                catch{
                    
                }
            },
            async onSubmit(){
                try{
                    let obj = {
                    statistics_price_id:this.gracelessId==1? this.priceId:undefined,
                    purchase_amount_price:this.gracelessId==2?this.value:undefined,
                    // purchase_amount_price:this.result,//实际采购成本
                    shipping_cost_price:this.shipping_cost_price,//运输成本
                    other_cost_price:this.other_cost_price,//其他成本
                    batch_number:this.dataLsta.batch_number,//运输批次号
                    type:this.gracelessId,
                    id: this.purchaseType == 3 ? this.dataLsta.id : undefined,
                    shipping_cost_type :  this.purchaseType != 3 ?this.shipping_cost_type:undefined
                }
                    let aa = encipherMent( JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",aa);
                    let url = this.purchaseType != 3 ?'index.php/batch/confirmPrice':'index.php/scrap_iron/confirmPrice'
                    const data = await is_post(url,param).then(res=>res)
                     this.$toast.success('操作成功')
                     this.$router.push('/Quality')

                }
                catch{

                }
                
            },
            //同伙价格
            onConfirm(e){
                console.log(this.priceArr)
                this.priceArr.forEach(i=>{
                    if(e.id == i.id){
                        this.goods =i
                    }
                })
                this.priceNmae = e.test
                this.priceId = e.id
                this.showPicker = false
            },
            onConfirm1(e){
                this.graceless = e.name
                this.gracelessId = e.id
                this.show = false
            },
            getType(){
                this.$http.post('index.php/statistics_price/_list').then(res=>{
                    let arr = []
                    this.priceArr = res.data.data
                     res.data.data.forEach(i => {
                         let obj = {
                             test:i.name + '('+ i.price + '吨' + ')',
                             id:i.id
                         }
                         arr.push(obj)
                     });
                    this.purchasePrice = arr
                })
            }
           
           
        },
    }
</script>

<style lang="scss" scoped>
.content{
    margin-top: 50px;
    padding: 10px;
    overflow: hidden;
}

    
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
.btn-buttom{
    margin-top: 50px;
   padding: 10px;
}
</style>